import { useState } from "react";
import _ from "lodash";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Field, Hint, Input, Label } from "@gocardless/flux-react";
import { Controller, useFormContext } from "react-hook-form";
import { checkVAT, countries } from "jsvat-next";

import { Field as Config } from "../config/types";
import { BusinessDetailsConfig } from "../../business-details/types";

import { error } from "./helpers";

import { CountryCodes } from "src/common/country";

interface VATNumberFieldProps {
  tooltip?: React.ReactElement;
}

const VATNumberField: React.FC<VATNumberFieldProps> = ({
  tooltip,
}): JSX.Element => {
  const { i18n } = useLingui();
  const {
    formState: { errors },
    getValues,
  } = useFormContext<BusinessDetailsConfig>();
  const [geo] = useState(getValues()?.geo);
  const isFR = geo === CountryCodes.FR;

  const taxNumberErrorMessages: Record<string, string> = {
    GB: i18n._(
      t({
        id: "setup.business-details.tax-number-valid-error-gb",
        message:
          "Please enter a valid VAT registration number. This is 9 numbers, sometimes with 'GB' at the start, for example GB000472631",
      })
    ),
    FR: i18n._(
      t({
        id: "setup.business-details.tva-number-valid-error",
        message: "Please enter a valid TVA number.",
      })
    ),
    any: i18n._(
      t({
        id: "setup.business-details.tax-number-valid-error",
        message: "Please enter a valid VAT registration number.",
      })
    ),
  };

  const taxNumberIsValid = (v: string) => {
    if (!geo) return true;

    if (!countries.some((country) => country.codes.includes(geo))) {
      return true; // Skip validation for unsupported countries
    }

    const { isValid } = checkVAT(v, countries);

    if (_.isEmpty(v) || isValid) {
      return true;
    }

    return taxNumberErrorMessages[geo] || taxNumberErrorMessages.any;
  };

  return (
    <Controller
      name="tax_number"
      rules={{
        validate: taxNumberIsValid,
      }}
      render={({ field }) => (
        <Field>
          <Label htmlFor="tax_number">
            {isFR ? (
              <Trans id="setup.business-details.tva-number">
                TVA number (optional)
              </Trans>
            ) : (
              <Trans id="setup.business-details.tax-number">
                VAT registration number (optional)
              </Trans>
            )}
            {tooltip}
          </Label>
          <Hint>
            {isFR ? (
              <Trans id="setup.business-details.tva-number-hint">
                Leave this blank if you are not TVA registered
              </Trans>
            ) : (
              <Trans id="setup.business-details.tax-number-hint">
                Leave this blank if you are not VAT registered
              </Trans>
            )}
          </Hint>
          <Input
            {...field}
            id="tax_number"
            className="fs-exclude"
            value={field.value}
            defaultValue={field.value}
          />
          {error(errors, "tax_number")}
        </Field>
      )}
    />
  );
};

export const config: Config = {
  name: "tax_number",
  displayName: (
    <Trans id="setup.business-details.tax-number">
      VAT registration number (optional)
    </Trans>
  ),
  component: VATNumberField,
};

export const FRConfig: Config = {
  name: "tax_number",
  displayName: (
    <Trans id="setup.business-details.tva-number">TVA number (optional)</Trans>
  ),
  component: VATNumberField,
};

export default VATNumberField;
